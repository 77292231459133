import React from "react";
import { ReactSelect } from "../FilterCard";
import domain from "../../Domain";
import {
  parseDate,
  toLocalDateTime,
  getPageOptions,
  getBrandingImage,
  hideModal,
  showModalNoOutsideClick,
  getNameFromID,
  removeNull,
  getSelectLabels,
} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import ReactToPrint from "react-to-print";
import { Sample } from "../../types/Sample";
import SimpleTable from "../tables/SimpleTable";
import { SamplesModal } from "../modals/SamplesModal";
import { HintModal } from "../modals/HintModal";
import { TestReport, TestReportAddtlInfo } from "../../types/TestReport";
import TestResult from "../TestResult";
import { ImageType } from "../../types/Branding";
import Select from "react-select";
import { BsCheck, BsFilter, BsFillRecord2Fill } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import TestType from "../../types/TestType";
import moment from "moment";
import DatePicker from "react-datepicker";
import { sweetalert } from "../../App";
import NetworkUtil from "../../network/NetworkUtil";
import TestsAPI from "../../network/TestsAPI";
import SystemAPI from "../../network/SystemAPI";
import SamplesAPI from "../../network/SamplesAPI";
import BatchesAPI from "../../network/BatchesAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import AdminAPI from "../../network/AdminAPI";
import { AdminPages } from "./AdminNav";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { BinAPI } from "../../network/BinAPI";
import { FaInfoCircle } from "react-icons/fa";
import PaginationTool from "../PaginationTool";
import ServicesAPI from "../../network/ServicesAPI";
import ViewTestInfoModal from "../modals/ViewTestInfoModal";
import SampleFilterTabs from "./SampleFilterTabs";
import { PodSchedForSelect } from "../../types/PodSched";
import { standardPatientFields } from "../../types/CustomFieldType";
import { ReportSelectModal } from "../modals/ReportSelectModal";
import PricingAPI from "../../network/PricingAPI";
import { PaymentFormModal } from "../modals/PaymentFormModal";
import PaymentAPI from "../../network/PaymentAPI";
import { demoEssentialUpgradeableServiceIDs, devEssentialUpgradableServiceIDs, productionEssentialUpgradableServiceIDs } from "../../types/Service";
//send filteredObj to backend to get data to display in table

interface ResultsReportState extends Sample {
  states: any;
  countries: any;
  gender: any;
  ethnicity: any;
  race: any;
  specimen_source: any;
  tests: any;
  testDetails: TestType[];
  pods: { label: string; value: number }[];
  selectedSample?: Sample;
  samples?: Sample[];
  totalSamples?: number;
  podsForModal: { label: string, value: number }[];
  showLoading: boolean;
  facilities?;
  facilitiesForSampleModal?
  facilityGroups?;
  resultTypes?;
  selectedPod?;
  hintHeader?;
  hintContent?;
  getResultsButtonEnabled?: boolean;
  isGlobal?: boolean;
  isAdmin?: boolean;
  direction: TableOrder;
  page_options: ReactSelect[];
  selected_page?;
  tableData?;
  pdfData?;
  PodID?;
  sampleArrowDown?;
  patientArrowDown?;
  validSpecimenSources?;
  validResultTypes?;
  batchesForFilter?;
  batchesForModal?;
  BatchNum;
  binIDsForFilter?;
  binIDsForModal?;
  BinID;
  isGlobalAdminorLab?;
  searched?;
  customFields?
  customQuestions?;
  services?;
  filteredObj
  customPatientFields?;
  showLoadingResultReport;
  associatedPrices?
  savedPaymentInfo?
  paymentInfo: { formData, totalAmount, lineItems }
  showPaymentInfo?: boolean
  facilityLabels
  facilitiesForPaymentModal
  facilityIDFromAdminHomeButtons?
  processing?
  completed?
}

export default class SamplesReport extends React.Component<
  any,
  ResultsReportState
> {
  private componentRef: any;
  private componentRefBR: any;
  private sampleFilterTabsRef: any;
  private paymentFormRef: any;

  constructor(props) {
    super(props);
    this.state = {
      selected_page: { label: 1, value: 1 },
      sampleArrowDown: false,
      patientArrowDown: false,
      ApptCanceled: [{ label: "No", value: "*null" }],
      RerunFlag: [{ label: "No", value: "*null" }],
      states: [],
      countries: [],
      gender: [],
      ethnicity: [],
      race: [],
      specimen_source: [],
      tests: [],
      binIDs: [],
      batches: [],
      showLoading: false,
      pods: [],
      facilities: [],
      facilityGroups: [],
      samples: [],
      totalSamples: 0,
      resultTypes: [],
      podsForModal: [],
      direction: "asc",
      sampleModalOpen: false,
      page_options: [{ value: "1", label: "1" }],
      filteredObj: {},
      processing: false,
      completed: false,
      hintHeader: "Samples Filter Hints",
      hintContent: [
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Click on table headers to sort.</div>,
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Place cursor over search labels for field description.</div>,
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Filter icon ( <BsFilter color={"#21212E"} size={25} /> ) next to tab header indicates there is value present with that tab.</div>,
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Dark blue check icon ( <BsCheck color={'#21212E'} size={25} /> ) next to search field indicates there is value present.</div>,
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Use <span style={{ color: '#C31230', fontWeight: 'bold' }}>'No Data'</span> to search for records that do not have data in the field.</div>,
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> Use <span style={{ color: '#21212E', fontWeight: 'bold' }}>'Has Data'</span> to search for records that have data in the field.</div>,
        <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}><BsFillRecord2Fill color={"#21212E"} size={25} /> To get records for a single day, put the same day in both boxes.</div>,
      ],
      showLoadingResultReport: false,
      associatedPrices: [],
      showPaymentInfo: false,
      paymentInfo: { formData: null, totalAmount: 0, lineItems: null },
      savedPaymentInfo: false,
      facilitiesForPaymentModal: []
    } as any;
    this.submit = this.submit.bind(this);
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
    this.sampleFilterTabsRef = React.createRef();
    this.viewPDFNewTab = this.viewPDFNewTab.bind(this);
    this.paymentFormRef = React.createRef();
    this.handlePaymentInfo = this.handlePaymentInfo.bind(this);
  }

  addNullSelection(data) {
    return data.concat([
      { label: "No Data", value: "*null", color: '#C31230', fontWeight: 'bold' },
      { label: "Has Data", value: "<>null", color: '#21212E', fontWeight: 'bold' },
    ]);
  }

  submit(page) {
    this.setState({ showLoading: true }, async () => {
      hideModal(SamplesModal.ID);
      try {
        let returnedFilteredObj = this.sampleFilterTabsRef.current.preSubmitChecks()
        if (!returnedFilteredObj) {
          this.setState({ tableData: [], showLoading: false, selected_page: { label: 1, value: 1 } });
          return
        }

        let result = await SamplesAPI.getFilteredSamples(page, {
          filter: returnedFilteredObj,
        });
        if (result.totalSamples < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "No samples found",
          });
          this.setState({ showLoading: false, tableData: [], totalSamples: 0, selected_page: { label: 1, value: 1 } });
          return;
        }
        hideModal(SamplesModal.ID);
        this.setState({
          totalSamples: result.totalSamples,
          showLoading: false,
          page_options: getPageOptions(result.num_pages),
          tableData: result.table_data,
          searched: returnedFilteredObj,
          selected_page: { label: page, value: page }
        });
      } catch (e) {
        console.error(e);
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "Search failed. Please try again.",
        });
        this.setState({ tableData: [], showLoading: false, selected_page: { label: 1, value: 1 } });
      }
    });
  }

  async loadData() {
    await Promise.all([
      AdminAPI.getPermissions().then((data) => {
        this.setState({
          isGlobalAdminorLab: data.isGlobalAdminorLab,
          isGlobal: data.isGlobal,
          isAdmin: data.isAdmin,
        });
      }),
      SystemAPI.getAllStates().then((data) => {
        this.setState({ states: this.addNullSelection(data) });
      }),
      SystemAPI.getAllCountries().then((data) => {
        this.setState({ countries: this.addNullSelection(data) })
      }),
      SystemAPI.getAllRaces().then((data) => {
        this.setState({ race: this.addNullSelection(data) })
      }),
      SystemAPI.getAllGenders().then((data) => {
        this.setState({ gender: this.addNullSelection(data) })
      }),
      SystemAPI.getAllEthnicities().then((data) => {
        this.setState({ ethnicity: this.addNullSelection(data) })
      }),
      SystemAPI.getAllSpecimenSources().then((data) => {
        this.setState({ specimen_source: this.addNullSelection(data) })
      }),
      TestsAPI.getValidSpecimenSources().then((data) => {
        this.setState({ validSpecimenSources: data })
      }),
      SystemAPI.getAllTests().then((data) => {
        this.setState({ tests: this.addNullSelection(data) })
      }),
      TestsAPI.getAllTestDetails().then((data) => {
        this.setState({ testDetails: data.tests })
      }),
      AdminAPI.getAllResultTypes().then((data) => {
        this.setState({ resultTypes: this.addNullSelection(data) })
      }),
      TestsAPI.getValidResultTypes().then((data) => {
        this.setState({ validResultTypes: data })
      }),
      AdminAPI.getAllPods(true).then((response) => {
        const today = moment().startOf('day')
        let pods = response.data as { label: string, value: PodSchedForSelect }[]

        const beforeToday = pods.filter((pods) => {
          let aDate = moment(pods.value.TestDate, 'YYYY-MM-DD').startOf('day')
          return aDate.isBefore(today)
        }).reverse()

        const afterToday = pods.filter((pods) => {
          let bDate = moment(pods.value.TestDate, 'YYYY-MM-DD').startOf('day')
          return bDate.isSameOrAfter(today)
        })
        let groupedPods = [
          { label: "Upcoming", options: afterToday },
          { label: "Past", options: beforeToday }
        ]
        this.setState({ podsForModal: this.addNullSelection(groupedPods) })
      }),
      CustomFieldsAPI.getAllCustomFields().then((data) => {
        this.setState({
          customFields: data.data.customFields.filter(f => f.Options.validPages.includes("Submission Form") || f.Options.validPages.includes("Submission") || f.Options.validPages.includes(AdminPages.Samples)),
          customQuestions: data.data.customQuestions,
          customPatientFields: data.data.customPatientFields
        })
      }),
      BatchesAPI.getBatchesForSamples().then((data) => {
        this.setState({ batchesForModal: data })
      }),
      ServicesAPI.getAllServices().then((data) => {
        this.setState({
          services: data.data,
        })
      }),
      BatchesAPI.getAllBatches().then((data) => {
        let batchesMapped = data.map((b) => {
          return {
            label: b.BatchNum,
            value: b.ID,
          }
        })
        this.setState({
          batchesForFilter: this.addNullSelection(batchesMapped),
        })
      }),
      BinAPI.getBinsForSamples().then((data) => {
        this.setState({ binIDsForModal: data })
      }),
      BinAPI.getAllBins().then((data) => {
        let binsMapped = data.map((b) => {
          return {
            label: b.BinID,
            value: b.ID,
          }
        })
        this.setState({ binIDsForFilter: this.addNullSelection(binsMapped) })
      }),
      BinAPI.getBinsForSamples().then((data) => {
        this.setState({ binIDs: this.addNullSelection(data) } as any)
      }),

      AdminAPI.getFacilitiesForSubmissionForm().then((data) => {
        let facilityNamesMapped = data.authorizedFacilities.map((fac) => {
          return {
            label: fac.FacilityName,
            value: fac.ID,
          }
        })

        let facilityGroupsMapped = data.authorizedFacilities.filter((f) => {
          let facilityIsConsentEntity = f.FacilitySeq != 1 && f.OktaID != null
          if (facilityIsConsentEntity || f.FacilitySeq === 1) return f
        })

        facilityGroupsMapped = facilityGroupsMapped.filter(
          (f) => f.FacilitySeq === 1
        )
        let facilityGroupsMappedNew = facilityGroupsMapped.map((fac) => {
          return {
            label: fac.FacilityGroup,
            value: fac.FacilityGroup,
          }
        })
        facilityGroupsMappedNew = facilityGroupsMappedNew.sort((a, b) => {
          let firstIndex = a.value.indexOf(" ")
          let first = a.value.slice(firstIndex)
          let secondIndex = b.value.indexOf(" ")
          let second = b.value.slice(secondIndex)
          return first - second
        })
        this.setState({
          facilities: this.addNullSelection(facilityNamesMapped),
          facilityGroups: this.addNullSelection(facilityGroupsMappedNew),
          facilitiesForSampleModal: data.authorizedFacilities.map(f => { return { label: f.FacilityName, value: f.ID } }),
          facilitiesForPaymentModal: data.authorizedFacilities
        })
      }),
      // show only 'Upgrade' pricing on Upgrade Samples button
      PricingAPI.getAllFacilityPricesForService(false, null, null).then((response) => {
        this.setState({ associatedPrices: response.data })
      })
    ])

    this.submit(1);
  }

  async componentDidMount() {
    document.title = 'Samples Page';
    const query = new URLSearchParams(window.location.search);

    const facilityID = query.get('f');
    const processing = query.get('p');
    const completed = query.get('c');

    // Set initial state based on query params
    this.setState({
      facilityIDFromAdminHomeButtons: facilityID ? Number(facilityID) : undefined,
      processing: !!Number(processing),
      completed: !!Number(completed),
      showLoading: true
    }, () => {
      this.loadData(); // Function to load data from various APIs
    });
  }



  handleExportToCSV() {
    let filter = this.state.searched;
    if (this.state.isGlobalAdminorLab) {
      sweetalert
        .fire({
          title: "Should your export include Patient Identifiers?",
          showCloseButton: true,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Include",
          denyButtonText: `Don't include`,
        })
        .then((result) => {
          if (result.isConfirmed || result.isDenied) {
            let modifiedfilter = Object.assign(filter, {
              IncludePHI: result.isConfirmed ? true : false,
            });
            this.setState({ showLoading: true }, async () => {
              await NetworkUtil.downloadCSV(
                "/api/admin/samples/results/csv",
                "Sample Results.xlsx",
                { filter: modifiedfilter });
              this.setState({ showLoading: false });
            });
          }
        });
    } else {
      this.setState({ showLoading: true }, async () => {
        await NetworkUtil.downloadCSV(
          "/api/admin/samples/results/csv",
          "Sample Results.xlsx",
          { filter: filter, IncludePHI: false });
        this.setState({ showLoading: false });
      });
    }
  }

  useSorter(col: Column) {
    let sorter = new Sorter<Sample>().withRawSorterFunc("Name", (a, b) => {
      let aName = a.PatientFirstName + " " + a.PatientLastName;
      let bName = b.PatientFirstName + " " + b.PatientLastName;
      return aName.localeCompare(bName);
    });
    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof Sample,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  handleSetResultsAccessed(val) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await SamplesAPI.updateResultsAccessedDate(val.ID);
        if (!result.success) {
          sweetalert.fire({ icon: "error", title: "", text: result.reason });
          this.setState({ showLoading: false });
          return;
        } else {
          sweetalert.fire({
            icon: "success",
            title: "",
            text: "Sample has been updated",
          });
          this.setState({ showLoading: false });
          await this.submit(1);
        }
      } catch (e) {
        console.error(e);
        sweetalert.fire({ icon: "error", title: "", text: "Server error" });
        this.setState({ showLoading: false });
      }
    });
  }

  needsPhysicianReview(selectedSample) {
    //check service for EmailReportTo === 1
    let emailReportTo = this.state.services && selectedSample && selectedSample.ServiceID && (new Date('2023-05-18') < new Date(selectedSample.CreatedDate)) ? this.state.services.find(s => s.ID === selectedSample.ServiceID).EmailReportTo : null
    //check sample for HasPhysicianReviewed
    if (emailReportTo === 1) {
      return true
    }
    return false
  }

  removeStandardPatientFields() {
    let customPatientFieldCopy = this.state.customPatientFields ? JSON.parse(JSON.stringify(this.state.customPatientFields)) : [];
    customPatientFieldCopy = customPatientFieldCopy.filter(f => !standardPatientFields.includes(f.DisplayName))
    return customPatientFieldCopy;
  }

  async viewPDFNewTab(path) {
    let report;
    try {
      this.setState({ showLoadingResultReport: true }, async () => {
        if (path === null) {
          let generate = await SamplesAPI.generateResultReportForReqNum(this.state.selectedSample.ReqNum);
          if (generate && generate.data) {
            report = await SamplesAPI.viewResultReport(generate.data);
            let data = await report.arrayBuffer();
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
          }
        } else {
          report = await SamplesAPI.viewResultReport(path);
          let data = await report.arrayBuffer();
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        }
        this.setState({ showLoadingResultReport: false })
      })
    } catch (error) {
      console.error(error);
      sweetalert.fire({ icon: "error", title: "Error", text: `Error opening report for viewing` })
    }
  }

  handlePaymentInfo = (response, totalPayment, lineItems) => {
    this.setState({
      savedPaymentInfo: true,
      paymentInfo: { formData: response, totalAmount: totalPayment, lineItems: lineItems },
    });

    const patientInfo = {
      SampleID: this.state.selectedSample?.ID,
      SpecimenID: this.state.selectedSample?.SpecimenID,
      ReqNum: this.state.selectedSample?.ReqNum,
      LinkedApptsID: this.state.selectedSample?.LinkedApptsID,
      PatientFirstName: this.state.selectedSample?.PatientFirstName,
      PatientLastName: this.state.selectedSample?.PatientLastName
    }

    this.setState({ showLoading: true }, async () => {
      try {
        const payment = await PaymentAPI.upgradePayment(this.state.paymentInfo, patientInfo);
        let paymentMessage = payment.paymentResponse?.messages?.message[0].text ? payment.paymentResponse.messages?.message[0].text : payment.reason
        if (!payment.success) {
          this.setState({ showLoading: false });
          // error on payment
          return sweetalert.fire({ icon: 'error', title: "Payment Error", html: paymentMessage })
        } else {
          if (payment.success && payment.paymentResponse?.messages?.message[0].text) {
            paymentMessage = `<p>${payment.paymentResponse?.messages?.message[0].text}<br> Payment Authorization: ${payment.paymentResponse?.transactionResponse?.authCode}<br>Transaction ID: ${payment.paymentResponse?.transactionResponse?.transId}</br>`
            // sweetalert.fire({ icon: 'success', title: "", html: paymentMessage})
          }
        }

        const upgradeSample = await SamplesAPI.upgradeSample(this.state.selectedSample, this.state.paymentInfo);
        const upgradeMessage = `<p>Comprehensive Report available for viewing.</p>`
        if (!upgradeSample.success) {
          this.setState({ showLoading: false });
          // error on upgrade
          return sweetalert.fire({ icon: 'error', title: "Upgrade Error", html: upgradeSample.reason })
        }

        this.setState({ showLoading: false });
        return sweetalert.fire({ icon: 'success', title: '', html: upgradeMessage + paymentMessage }).then(() => {
          this.paymentFormRef.current.clearPaymentFormState();
          this.submit(1); // reload the page
        })
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
        sweetalert.fire({ icon: 'error', title: '', text: "Upgrading sample failed. " + e.message })
      }
    });
  }
  refreshSelectedFacility() {
    // ANetCustomerProfile created for Facility via PaymentFormModal
    // reload facilities and selectedFacility passed into PaymentFormModal?
    AdminAPI.getFacilitiesForSubmissionForm().then(data => {
      this.setState({
        facilities: data.authorizedFacilities,
        facilityLabels: getSelectLabels(data.authorizedFacilities)
      })
    })
  }

  getIsUpgradeable(sample) {
    if (sample && sample.ServiceID) {
      if (process.env.REACT_APP_ENV === 'production') {
        if (productionEssentialUpgradableServiceIDs.includes(sample.ServiceID)) {
          return true;
        }
      }
      else if (process.env.REACT_APP_ENV === 'demo') {
        if (demoEssentialUpgradeableServiceIDs.includes(sample.ServiceID)) {
          return true;
        }
      }
      else {
        if (devEssentialUpgradableServiceIDs.includes(sample.ServiceID)) {
          return true;
        }
      }
    }
    return false;
  }

  getTransactionStatus(transID) {
    this.setState({ showLoading: true }, () => {
      PaymentAPI.queryTransaction({ TransID: transID, updateStatus: true }).then((response) => {
        let updatedTableData = this.state.tableData.map(sample => {
          if (sample.TransactionID === transID) {
            return { ...sample, TransactionStatus: response.data.transaction.transactionStatus === 'settledSuccessfully' ? 'Settled' : response.data.transaction.transactionStatus === 'refundSettledSuccessfully' ? 'Refund Settled' : 'Unsettled' };
          }
          return sample;
        });
        this.setState({ tableData: updatedTableData, showLoading: false });
      })
    });
  }

  render(): | React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

    // console.log('SamplesReport state', this.state)

    const isDataLoaded = this.state.facilities?.length > 0 && this.state.services?.length > 0;

    let services = this.state.services ? this.state.services.map(s => { return { label: s.Name, value: s.ID } }) : []
    services = this.state.services ? this.addNullSelection(services) : services;
    // Any Essential Service with test result date after release is eligible for 'Upgrade'

    return (
      <React.Fragment>
        <PaginationTool />
        <div className="container-fluid  min-vh-100 ">
          <Overlay show_loading={this.state.showLoading} />
          <Overlay show_loading={this.state.showLoadingResultReport} zIndex={100010} />

          <SamplesModal
            key={this.state.selectedSample && this.state.selectedSample.ID ? this.state.selectedSample.ID : 'new'}
            onSubmit={() => this.submit(1)}
            isAdmin={this.state.isAdmin}
            isGlobal={this.state.isGlobal}
            getResultsButtonBoolean={this.state.getResultsButtonEnabled}
            getPhysicianReviewButtonBoolean={this.needsPhysicianReview(this.state.selectedSample)}
            selectedSample={
              this.state.selectedSample ? this.state.selectedSample : {}
            }
            states={removeNull(this.state.states)}
            countries={removeNull(this.state.countries)}
            ethnicity={removeNull(this.state.ethnicity)}
            race={removeNull(this.state.race)}
            gender={removeNull(this.state.gender)}
            specimen_source={this.state.specimen_source}
            validSpecimenSources={this.state.validSpecimenSources}
            batches={this.state.batchesForModal}
            binIDs={this.state.binIDsForModal}
            testDetails={this.state.testDetails}
            tests={removeNull(this.state.tests)}
            result_types={this.state.resultTypes}
            validResultTypes={this.state.validResultTypes}
            facilities={this.state.facilitiesForSampleModal}
            pods={removeNull(this.state.podsForModal)}
            customFields={this.state.customFields}
            customQuestions={this.state.customQuestions}
            services={this.state.services}
            customPatientFields={this.removeStandardPatientFields()}
          />

          <HintModal
            handleInBetweenOverlay={() => { }}
            label={"Sample Filters"}
            header={this.state.hintHeader}
            content={this.state.hintContent}
          />

          <ViewTestInfoModal
            selectedSample={this.state.selectedSample ? this.state.selectedSample : {}}
            tests={this.state.tests}
            results={this.state.resultTypes}
            services={this.state.services}
            getResultsButtonBoolean={this.state.getResultsButtonEnabled}
          />
          <ReportSelectModal header="Result Report"
            zIndex={100009}
            reqNum={this.state.selectedSample?.ReqNum}
            handleInBetweenOverlay={() => { }}
            handleViewPDF={(filePath) => {
              this.viewPDFNewTab(filePath);
            }}
          />

          <PaymentFormModal
            ref={this.paymentFormRef}
            // key={this.state.showLoading}
            selectedServices={
              this.state.services?.filter(s => s.ID === this.state.selectedSample?.ServiceID)
            }
            associatedPrices={this.state.associatedPrices} // todo need to set this array for selected Sample
            handlePaymentInfo={this.handlePaymentInfo}
            selectedFacility={this.state.facilitiesForPaymentModal?.find(
              (f) => f.ID === this.state.selectedSample?.FacilityID)
            }
            refreshSelectedFacility={this.refreshSelectedFacility}
            states={this.state.states}
            countries={this.state.countries}
            allowDiscountEntry={true}
            fromWalkin={false}
          />

          <div className="container-fluid  min-vh-100 ">
            <main id="main-content" tabIndex={-1} aria-label="Samples">
              {isDataLoaded && (
                <div className="card m-2">
                  <div className="card-header verlag-bold text-center text-md-left" style={{ height: "4em" }}>
                    <h4 >
                      Samples{" "}
                      <FaInfoCircle
                        tabIndex={0}
                        aria-label="Sample Filter Hints"
                        role="button"
                        className={"ml-1"}
                        size={25}
                        color={"#21212E"}
                        onClick={() => showModalNoOutsideClick(HintModal.ID)}
                      />
                    </h4>
                  </div>


                  <SampleFilterTabs
                    ref={this.sampleFilterTabsRef}
                    services={services}
                    batchesForFilter={this.state.batchesForFilter}
                    binIDsForFilter={this.state.binIDsForFilter}
                    resultTypes={this.state.resultTypes}
                    specimenSources={this.state.specimen_source}
                    tests={this.state.tests}
                    pods={this.state.podsForModal}
                    facilityGroups={this.state.facilityGroups}
                    facilities={this.state.facilities}
                    states={this.state.states}
                    countries={this.state.countries}
                    ethnicity={this.state.ethnicity}
                    race={this.state.race}
                    gender={this.state.gender}
                    fromAdminHomeButtons={{ FacilityID: this.state.facilityIDFromAdminHomeButtons, processing: this.state.processing, completed: this.state.completed }}
                  />


                  <div className="card-footer" style={{ height: "4em" }}>
                    <button
                      className="btn mr-md-5 float-right verlag-bold immyClearButtonOutline"
                      onClick={() => {
                        this.sampleFilterTabsRef.current.clear();
                      }}
                    >
                      Clear Filters
                    </button>
                    <button
                      className={"btn verlag-bold immySubmitButtonOutline"}
                      onClick={() => this.submit(1)}
                    >
                      Search
                    </button>
                  </div>
                </div>
              )}

              {this.state.totalSamples > 0 ? (
                <div className="col-12 pt-2 pr-0 pl-0 pb-3">
                  <div className="card m-2 mb-5">
                    <div className="card-header verlag-bold stickToTop">
                      <h4 className={"text-center text-md-left"}>
                        Samples
                        <button
                          className={
                            this.state.totalSamples < 1
                              ? "d-none"
                              : "btn btn-outline-primary d-none d-md-inline-block d-xxl-inline-block ml-2"
                          }
                          onClick={() => this.handleExportToCSV()}
                        >
                          Export to CSV
                        </button>
                        <ReactToPrint
                          trigger={() => (
                            <button
                              className={
                                this.state.totalSamples < 1
                                  ? "d-none"
                                  : "btn btn-outline-success ml-2 d-none d-md-inline-block d-xxl-inline-block"
                              }
                            >
                              Print Grid to PDF
                            </button>
                          )}
                          // @ts-ignore
                          content={() => this.componentRef}
                        />

                        {/* uncomment if want to Print Results to PDF again.  will need reworked for multiple report types  */}
                        {/* <ReactToPrint
                        trigger={() => (
                          <button
                            className={
                              this.state.totalSamples < 1
                                ? "d-none"
                                : "btn btn-outline-success ml-2 d-none d-md-none d-lg-inline-block d-xxl-inline-block"
                            }
                          >
                            Print Results to PDF
                          </button>
                        )}
                        // @ts-ignore
                        content={() => this.componentRefBR}
                        onBeforeGetContent={() => {
                          if (
                            !this.state.tableData ||
                            this.state.tableData.length === 0
                          ) {
                            sweetalert.fire({
                              icon: "error",
                              title: "",
                              text: "No samples found",
                            });
                            return;
                          }
                          return new Promise<void>((resolve, reject) => {
                            this.setState({ showLoading: true }, () => {
                              fetch(
                                domain +
                                  "/api/admin/sampleReport?page=" +
                                  this.state.selected_page.value,
                                {
                                  method: "POST",
                                  body: JSON.stringify({
                                    filter: this.state.filteredObj,
                                  }),
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((res) => res.json())
                                .then((data) => {
                                  this.setState({
                                    pdfData: data.pdfData,
                                    showLoading: false,
                                  });
                                  resolve();
                                })
                                .catch((e) => {
                                  console.error(e);
                                  sweetalert.fire({
                                    icon: "error",
                                    title: "",
                                    text: "Export failed. Please try again.",
                                  });
                                  this.setState({
                                    pdfData: [],
                                    showLoading: false,
                                  });
                                  resolve();
                                });
                            });
                          });
                        }}
                      /> */}
                        <section className="tableHeaderSection float-md-right d-flex justify-content-around">

                          <h4 className={"float-right"} aria-label="Total Records" role="alert">
                            Total: {this.state.totalSamples}
                          </h4>
                          <h4 className="pl-5 pl-md-0 float-md-right align-items-md-center pr-2 ml-md-5">
                            Page
                          </h4>
                          <div className="align-items-md-center float-right pages">
                            <Select
                              isSearchable={true}
                              placeholder={"1"}
                              noOptionsMessage={() => "No option"}
                              aria-label="Table Page Number"
                              value={this.state.selected_page}
                              onChange={(e: ReactSelect) =>
                                
                                this.setState({ selected_page: e }, () =>
                                  this.submit(e.value)
                                )
                              }
                              className={"state_select page-num-select"}
                              options={this.state.page_options}
                            />
                          </div>
                        </section>
                      </h4>
                    </div>

                    <div className="p-0 table-responsive">
                      <SimpleTable ref={el => (this.componentRef = el)} columns={[
                        {
                          label: "Name", key: "Name", rawFormat: (val: Sample) => {
                            return <a href={"#top"}
                              className={'tableNameLinkColor'}
                              onClick={() => this.setState({ getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false, selectedSample: JSON.parse(JSON.stringify(val)) }, () => showModalNoOutsideClick(SamplesModal.ID))}>{val.PatientFirstName + " " + val.PatientLastName}</a>
                          }
                        },
                        { label: "DOB", key: "PatientDOB", formatFunc: parseDate },
                        { label: "Appt #", key: "LinkedApptsID" },
                        { label: "Conf #", key: "ReqNum" },
                        { label: "Collection Date", key: "CollectionDate", formatFunc: toLocalDateTime },
                        { label: "Phone #", key: "PatientPhone" },
                        {
                          label: "Facility", key: "FacilityID", rawFormat: (val) => {
                            if (val.FacilityID) {
                              let facilityName = getNameFromID(val.FacilityID, this.state.facilities);
                              if (!facilityName) {
                                return <p></p>
                              }
                              return <p>{facilityName}</p>
                            }
                            else {
                              return <p></p>
                            }
                          }
                        },
                        { label: "Specimen ID", key: "SpecimenID" },
                        { label: "Int. Specimen ID", key: "InternalSpecimenID" },
                        { label: "Batch #", key: "BatchNum" },
                        {
                          label: "Service", key: "ServiceID", rawFormat: (val) => {
                            if (val.ServiceID) {
                              let serviceName = getNameFromID(val.ServiceID, this.state.services);
                              if (!serviceName) {
                                return <p></p>
                              }
                              return <p>{serviceName}</p>
                            }
                            else {
                              return <p></p>
                            }
                          }
                        },
                        {
                          label: "Result Accessed", key: "ResultsAccessedDate",
                          rawFormat: (val: Sample) => (
                            val.ResultsAccessedDate ?
                              <div>{toLocalDateTime(val.ResultsAccessedDate)}</div> :
                              <button
                                type={'button'}
                                className={val.ResultID ? 'btn btn-outline-success' : "d-none"}
                                onClick={() => this.handleSetResultsAccessed(val)}>Mark as Accessed
                              </button>)
                        },
                        { label: "Transaction ID", key: "TransactionID", rawFormat: (val: Sample) => (
                          val.TransactionID ?
                            <div>{JSON.parse(val.TransactionID).toString()}</div> :
                            <div></div>) },
                        {
                          label: "Test Info", key: "",
                          rawFormat: (val: Sample) => (
                            JSON.parse(val.TestIDs)?.length > 0 ?
                              <button
                                type={'button'}
                                className={'btn btn-outline-success'}
                                onClick={() => this.setState({ getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false, selectedSample: JSON.parse(JSON.stringify(val)) }, () => showModalNoOutsideClick(ViewTestInfoModal.ID))}>{JSON.parse(val.TestIDs).length > 1 ? 'View Tests Info' : 'View Test Info'}
                              </button> : <div>No Test Assigned</div>)
                        },
                        {
                          label: "", key: "",
                          rawFormat: (val: Sample) => (
                            this.getIsUpgradeable(val)
                              ?
                              <button
                                type={'button'}
                                className={'btn btn-outline-success'}
                                onClick={() =>
                                  this.setState({
                                    getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false,
                                    selectedSample: JSON.parse(JSON.stringify(val))
                                  }, () =>
                                    showModalNoOutsideClick(PaymentFormModal.ID)
                                  )}
                              >
                                {JSON.parse(val.TestIDs).length ? 'Upgrade' : ''}
                              </button> : <div></div>
                          )
                        }
                      ]} table_data={this.state.tableData} columnClickedCallback={(col => {
                        this.useSorter(col);
                      })} />
                    </div>
                  </div>
                </div>
              ) : null}
            </main>
          </div>
        </div>

      </React.Fragment>
    );
  }
}