import React from "react";
import ModalMessage from "../modals/ModalMessage";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import {sweetalert} from "../../App";
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import AlertAPI from "../../network/AlertAPI";
import AdminAPI from "../../network/AdminAPI";

interface AlertState{
    // text:string
    selectedPod?:string
    showLoading:boolean
    filter?:{text?: string}
    filterTwo?:{email?:string, cell?:string}
    podLabel?
    pods?
    isSubmitting?:boolean
}

class Alert extends React.Component<any, AlertState>{

    constructor(props) {
        super(props);
        this.state = {
            // text:"",
            showLoading:false,
            filter:{},
            filterTwo:{},
            isSubmitting: false
        }
        this.assignClearState = this.assignClearState.bind(this);
        this.assignClear2State = this.assignClear2State.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            AdminAPI.getAllPods(false,false).then(response => {
                if(!response.success){
                    sweetalert.fire("Error loading Pods")
                }
                this.setState({pods: response.data, showLoading: false})
            })
        })
    }

    clearFilterState:() => void = null;


    assignClearState(func){
        this.clearFilterState = func;
    }

    clearState = () =>{
        this.clearFilterState();
    }

    clearFilter2State:() => void = null;

    assignClear2State(func){
        this.clearFilter2State = func;
    }

    clear2State = () =>{
        this.clearFilter2State();
    }

    clear() {
        this.clearState()
        this.clear2State()
    }

    onSubmit(){
        let formValidation = {
            Text: this.state.filter.text,
            SelectedPod: this.state.selectedPod,
            Email: this.state.filterTwo.email,
            Cell: this.state.filterTwo.cell,
        }

        let emails = formValidation.Email.split(',')
      .map(email => email.trim())
      .filter(email => email !== '');
    let cells = formValidation.Cell.split(',')
      .map(cell => cell.trim())
      .filter(cell => cell !== '');

    //validate the emails
    let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let invalidEmails = emails.filter(email => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      //change array to string
      let invalidEmailsString = invalidEmails.join(', ');
      return sweetalert.fire({ icon: 'error', title: '', html: `<p>The following email(s) are not valid:</p> <p style="font-weight: bold;">${invalidEmailsString}</p>` });
    }

    //validate the cells
    let cellRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    let invalidCells = cells.filter(cell => !cellRegex.test(cell));

    if (invalidCells.length > 0) {
      let invalidCellsString = invalidCells.join(', ');
      return sweetalert.fire({ icon: 'error', title: '', html: `<p>The following cell number(s) are not valid:</p> <p style="font-weight: bold;">${invalidCellsString}</p>` });
    }


        let validator = new Validator<any>()
            .withSimpleValidation("Text", Validators.requireNotBlankValidator("Message"))

        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        
        if (
            this.state.filter.text &&
            this.state.filter.text.length > 0 &&
            !this.state.selectedPod &&
            (!this.state.filterTwo.email || this.state.filterTwo.email.length < 1) &&
            (!this.state.filterTwo.cell || this.state.filterTwo.cell.length < 1)
          ) {
            return sweetalert.fire({ icon: 'error', title: '', text: 'Please enter an email and/or cell number' });
            // return sweetalert.fire({ icon: 'error', title: '', text: 'Please choose pod or enter email and/or cell number' });
          }
          this.onConfirm();
    }

    async onConfirm(){
        this.setState({showLoading:true, isSubmitting:true}, async()=>{
            let formattedCellNumbers = this.state.filterTwo.cell
            .split(',')
            .map(number => number.trim()) // Remove any whitespace
            .map(number => `+1${number.replace(/-/g, '')}`)
            .join(', '); // Add +1 and remove hyphens;
            try{
                let result = await AlertAPI.sendAlert({
                    text:this.state.filter.text, 
                    pod:this.state.selectedPod, 
                    email:this.state.filterTwo.email, 
                    cell:formattedCellNumbers === '+1' ? '' : formattedCellNumbers,
                })
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: `Alerts sent successfully.`});
                    this.clear()
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason})
                }
            }
            catch (e) {
                sweetalert.fire({icon: 'error', title: '', text: 'Failed sending alert'})
                console.error(e)
            }
            this.clear()
            this.clearState()
            this.clear2State()
            this.setState({showLoading:false, isSubmitting: false});
    })
    }

    getPodLabel(id){
        let podLabel = this.state.pods.find(p => p.value === id)
        if(!podLabel){
            return null;
        }
        return {label: podLabel.label, value: id}
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>

            <div className="container-fluid  min-vh-100 ">
                <div className={"row"}>
                    <div className="col-xxl-4 col-lg-12 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h4>Messages</h4>
                            </div>
                            <div className={"card-body"}>
                                {/* <p className={"responsive-p verlag-light mb-4"}>If Pod is selected, an email and text message will be sent to all individuals signed up for the pod.</p> */}
                                {/*<PodSelect onChange={(e) => this.setState({selectedPod:e.pod})}/>*/}
                                {/* <div className="form-group row">
                                    <div className={"col-4"}>
                                        <label htmlFor={'Pod'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Pod</label>
                                    </div>
                                    <div className={"col-8 pl-0 pr-1"} id={'Pod'}>
                                        <Select
                                            isSearchable={true}
                                            placeholder={"Please select..."}
                                            onChange={(e)=> {this.getPodLabel(e.value); this.setState({podLabel: e, selectedPod: e.value}) } }
                                            className={"state_select"}
                                            options={this.state.pods}
                                            value={this.state.podLabel}
                                        />
                                    </div>
                                </div> */}
                                <FilterCard passClearStateFunc={this.assignClearState} fields={[
                                    {label:"Message", key:"text", rows: 5, type:FIELD_TYPE.TEXT_AREA, maxLength: 1000},
                                    ]} filterChanged={f =>this.setState({filter:f})}/>
                                    
                                    <p className={'responsive-p verlag-light mb-4'}>
                    Add multiple recipients separating each email/cell number with a comma. Cell number format must be ###-###-####.
                  </p>
                                <FilterCard passClearStateFunc={this.assignClear2State} fields={[
                                     { label: 'Email', key: 'email', type: FIELD_TYPE.TEXT, textType: 'email', maxLength: 1000 },
                                     { label: 'Cell Number', key: 'cell', type: FIELD_TYPE.TEXT, textType: 'phone', maxLength: 1000 }]} filterChanged={f =>this.setState({filterTwo:f})}/>
                            </div>
                            <div className={"card-footer"}> <button className={"btn immySubmitButtonOutline"} onClick={this.onSubmit}> Send</button></div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export  default Alert