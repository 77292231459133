import React from "react";
import { isEmptyObject } from "jquery";
import { Transaction } from "../../types/Transaction";
import { hideModal } from "../../util/FormatUtil";
import ReactToPrint from "react-to-print";
import moment from "moment";

interface TransactionDetailsModalState{
    selectedTransaction:Transaction
}

interface TransactionDetailsModalProps{
    selectedFacility
    selectedTransaction:Transaction
    transactions?:Transaction[]
    onSubmit?:(transaction:Transaction)=>void
    onClose?:()=>void
}
export class TransactionDetailsModal extends React.Component<TransactionDetailsModalProps,TransactionDetailsModalState>{
    public static ID = "transactiondetails"
    private componentRef: any;

    constructor(props){
        super(props);
        this.state = {
            selectedTransaction: {} as Transaction
        }
        this.clearFields = this.clearFields.bind(this);
    }

    componentWillReceiveProps(nextProps:Readonly<TransactionDetailsModalProps>,nextContext:any){
        if(!isEmptyObject(nextProps.selectedTransaction)){
            let transaction:Transaction = nextProps.selectedTransaction;
            this.setState({
                selectedTransaction: transaction
            })
        }
    }

    clearFields(){
        this.setState({
            selectedTransaction: {} as Transaction 
        })
    }
    

    render(){
        return(
            <div className="modal fade form_modal" 
                id={TransactionDetailsModal.ID} tabIndex={-1} role="dialog"
                style={{zIndex: 100006}}
                aria-labelledby="result_modal_label"
                aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="container-fluid">
                            <div className="modal-body m-3">
                                <div className="col-md-12" ref={el => (this.componentRef = el)}>
                                    <div style={{ textAlign: "center" }}>
                                        <img src="https://storage.googleapis.com/streamline-bucket/OraPath_logo_w_shadow.png"
                                            alt="orapath logo"
                                            width="200"
                                        />
                                    </div>
                                    <br />
                                    <div className="text-align-center"><b>Linked Appts ID </b>{this.state.selectedTransaction?.order?.invoiceNumber.slice(4)}</div>
                                    <div className="row ">
                                        <div className="border border-4 border-info rounded-top pt-2 mb-2 col-sm-12">
                                            <h4 className="border-bottom">Transaction Receipt</h4>
                                            <div className="row">
                                                <div className="col-6"><b>Merchant:</b></div>
                                                <div className="col-6" >Immy Labs - (Norman, OK)</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Date/Time:</b></div>
                                                <div className="col-6" >{moment(this.state.selectedTransaction?.submitTimeUTC).format("MM/DD/YYYY HH:mm A ")}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Transaction Id:</b></div>
                                                <div className="col-6" >{this.state.selectedTransaction?.transId}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Transaction Type:</b></div>
                                                <div className="col-6" >{this.state.selectedTransaction?.transactionType}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Entry Method:</b></div>
                                                <div className="col-6" >{this.state.selectedTransaction?.order?.description}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Amount:</b></div>
                                                <div className="col-6" >${this.state.selectedTransaction?.settleAmount?.toFixed(2)}</div>
                                            </div>
                                        </div>

                                        <div className="border border-4 border-info rounded-top pt-2 mb-2 col-sm-12">
                                            <h4 className="border-bottom">Credit Card Information</h4>
                                            <div className="row">
                                                <div className="col-6"><b>CC Type:</b></div>
                                                <div className="col-6" >{this.state.selectedTransaction?.payment?.creditCard?.cardType}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>CC Number:</b></div>
                                                <div className="col-6" >{this.state.selectedTransaction?.payment?.creditCard?.cardNumber}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Auth Code:</b></div>
                                                <div className="col-6" >{this.state.selectedTransaction?.authCode}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Processor:</b></div>
                                                <div className="col-6">Authorize.net</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="border border-4 border-info rounded-top pt-2 mb-2 col-sm-6">
                                            <h4 className="border-bottom">Billing Information</h4>
                                            <div className="row">
                                                <div className="col-12">{this.props.selectedFacility?.FacilityName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">{this.state.selectedTransaction?.billTo?.firstName} {this.state.selectedTransaction?.billTo?.lastName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">{this.props.selectedFacility?.ProviderEmail}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">{this.state.selectedTransaction?.billTo?.phoneNumber}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">{this.state.selectedTransaction?.billTo?.address}</div>
                                                <div className="col-12">{this.state.selectedTransaction?.billTo?.city} {this.state.selectedTransaction?.billTo?.state} {this.state.selectedTransaction?.billTo?.zip}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">{this.state.selectedTransaction?.billTo?.country}</div>
                                            </div>
                                        </div>
                                        <div className="border border-4 border-info rounded-top pt-2 mb-2 col-sm-6">
                                            <h4 className="border-bottom">Shipping Information</h4>
                                            <div className="row">
                                                <div className="col-12">United States</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="border border-4 border-info rounded-top pt-2 mb-2 col-sm-12">
                                            <h4 className="border-bottom">Order Information</h4>
                                            <div className="row">
                                                <div className="col-6"><b>Profile Id:</b></div>
                                                <div className="col-6">{this.state.selectedTransaction?.profile?.customerProfileId}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Invoice Number: </b></div>
                                                <div className="col-6">{this.state.selectedTransaction?.order?.invoiceNumber}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6"><b>Description: </b></div>
                                                <div className="col-6">{this.state.selectedTransaction?.order?.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="border border-4 border-info rounded-top pt-2 mb-2 col-sm-12">
                                            <h4 className="border-bottom">Product Information</h4>
                                            <table width="100%">
                                                <tr>
                                                    <th>Item </th>
                                                    <th>Qty </th>
                                                    <th>Description </th>
                                                    <th style={{textAlign:"right"}}>Unit Cost </th>
                                                    <th style={{textAlign:"right"}}>Amount </th>
                                                </tr>
                                                { this.state.selectedTransaction?.lineItems?.lineItem.map(item => {
                                                    let itemIsDiscount = item.name.includes('Discount');
                                                    return (
                                                        <tr>
                                                            <td>{item.name}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.description}</td>
                                                            <td style={{textAlign:"right"}}>{itemIsDiscount? `-$`:`$`}{item.unitPrice.toFixed(2)}</td>
                                                            <td style={{textAlign:"right"}}>{itemIsDiscount? `-$`:`$`}{(item.unitPrice * item.quantity).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{textAlign:"right"}}><b>Subtotal</b></td>
                                                    <td style={{textAlign:"right"}}><b>${this.state.selectedTransaction?.settleAmount?.toFixed(2)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{textAlign:"right"}}><b>Sales Tax</b></td>
                                                    <td style={{textAlign:"right"}}></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{textAlign:"right"}}><b>Grand Total</b></td>
                                                    <td style={{textAlign:"right"}}><b>${this.state.selectedTransaction?.settleAmount?.toFixed(2)}</b></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <br />
                                    <h2 style={{ "textAlign": "left" }}>Questions?</h2>
                                    <div style={{ "textAlign": "left" }}>
                                        Email: help@immytech.com
                                    </div>
                                    <b>Office Hours: (Monday - Friday: 8:30 AM - 5:00 PM CST)</b>
                                </div>
                            </div>
                                
                            <div className="modal-footer">
                                <ReactToPrint
                                    trigger={() =><button className={"btn btn-outline-success float-right"}>Print</button>}
                                    content={() => this.componentRef}/>

                                <button type="button" className="btn btn-primary" 
                                    onClick={() => {
                                        hideModal(TransactionDetailsModal.ID)
                                        this.props.onClose()
                                    }}
                                >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}